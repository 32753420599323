.top_layout > div {
  height: inherit !important;
}

/* .margin_top_ticker {
  position: relative !important;
} */

.ticker_width_side_bar_collapsed {
  width: calc(100% - 115px);
  z-index: 99999999999;
}

.ticker_width_side_bar_not_collapsed {
  width: calc(100%);
  z-index: 99999999999;
}
