.bg-light-gray {
  background-color: #f1eeee !important;
}

.filter-logo {
  height: "17.02px";
  width: "16px";
}
.filter-logo:hover {
  background-color: #ef0855;
}

.button-pink {
  border: 2px solid #ef0855;
  background-color: white;
  color: #ef0855;
}
.button-pink:hover {
  background-color: #ef0855;
  color: white;
}

.button-pink-filter {
  background-color: #ef0855;
  color: white;
  border: none;
}
.button-pink-filter:hover {
  background-color: #ef0855;
  color: white;
  border: none;
}

.bg-button-green {
  background-color: #4dcc591a;
  border: 1px solid #4dcc59;
}

.bg-button-green:hover {
  background-color: #4dcc591a;
  border: 1px solid #4dcc59;
  color: black;
}

.bg-button-green:focus {
  background-color: #4dcc591a;
  border: 1px solid #4dcc59;
}

.bg-button-red {
  background-color: rgb(255, 215, 215) !important;

  border: 1px solid #ef0855;
}
.bg-button-red:hover {
  background-color: rgb(255, 215, 215);
  border: 1px solid #ef0855;
  color: black;
}
.bg-button-red:focus {
  background-color: rgb(255, 215, 215);
  border: 1px solid #ef0855;
  color: #ef0855 !important;
}
.border-pink {
  border: 1px solid #ef0855;
}
.border-gray {
  border: 1px solid #e8e4ee;
}
.border-bottom-gray {
  border-bottom: 1px solid #e8e4ee;
}
.background-white-btn {
  background-color: white !important;
}

.event-editor-increment-btn {
  background-color: #fbfbfb;
  border-color: #eae4f2;
}

.event-editor-dropdown-background-gray {
  background-color: #fbfbfb;
}

.event-editor-dropdown-div {
  /* max-height: 300px; */
  min-height: 250px;
}
.event-editor-tagged-agents-div {
  height: 130px;
  max-height: 100px;
  overflow-y: scroll;
}
.event-editor-tagged-agents-div::-webkit-scrollbar {
  display: none;
}
.ant-list-item {
  padding: 0;
  border: 0;
}

.event-editor-increment-btn:hover {
  background-color: white;
  border: 1px solid #ef0855;
}
.ant-input-group-addon:first-child {
  width: 80%;
  background-color: white;
  border: 0;
}

.minus-logo {
  /* background-color: #ef0855; */
  color: white;
  font-size: "16px";
  border: 0;
  padding: 4px;
}
.plus-logo {
  background-color: white;
  /* color: #ef0855; */
  font-size: "16px";
  border: 0;
  padding: 4px;
}
.event-date-scroll-div {
  max-height: 260px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.event-date-scroll-div::-webkit-scrollbar {
  display: none;
}
.event-setting-margin-left {
  margin-left: 60px;
}
.registration-and-communication-info-logo {
  background-color: #fbfbfb;
  border: 1px solid #dcd5e6;
  border-radius: 50%;
}
.navigation-reception-cube {
  color: #ef0855 !important;
  /* background-color:  rgb(238, 37, 70); */
}

.ant-breadcrumb {
  padding-bottom: 10px;
}
/* .ant-table-row:hover {
    font-weight: 500;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;;
} */

.ant-popover-title {
  border-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}
.activity-filter .ant-popover {
  width: 81% !important;
}
.w-81 {
  width: 81% !important;
}

.icon {
  position: relative;
  top: 0;
}
.popover-text {
  position: relative;
  /* top : 5% !important; */
  margin-top: 2px;
}
/* .ant-input{
    width : 10%;
} */

.cke_bottom {
  display: none !important;
}
.Modal-Country li:hover {
  background-color: #ffe8f0 !important;
}

.image-upload > input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.dropdownclass {
  z-index: 20;
  background-color: red;
}
.options > .anticon {
  vertical-align: middle;
}

.view-register-and-com .ant-switch-disabled {
  opacity: 1;
}

.disable-color {
  background: white;
  background-color: white;
  color: black;
}

.ant-picker.ant-picker-disabled {
  /* background: #f5f5f5; */
  /* border-color: #d9d9d9;
  cursor: not-allowed; */
  background: none;
}

.ant-picker-input > input[disabled] {
  color: black;
  /* background-color: #f5f5f5; */
  /* border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1; */
}

.uploaded-image-modal-preview {
  /* max-height: 200px; */
  max-height: 350px;

  /* border-radius: 40px; */
}
.table_inside_block {
  padding: 20px !important;
  background-color: #4dcc59;
}
