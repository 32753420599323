@import "~antd/dist/antd.less";

.notification_list_parent{
    display: flex;
    flex-direction: column;
    height: 280px;
    width: 300px;
    overflow-y: auto
}
.notification_list_child{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #dee2e6 !important;
    justify-content: space-between;
    padding: 3%;
}
.notification_list_child_labels{
    display: flex;
    flex-direction: column;
}
.notification_list_child_read{
    background-color: #fbfbfb;
}
.notification_list_child_text{
    color: black !important;
}
.notification_list_button_text{
    color:@primary-color !important;
    font-weight: bold;
}

//type 1 notification
.t1_notification_parent{
    background-color: @primary-color !important;
    width: 350px !important;
    // height: 150px !important;
    top: 60px !important;
}
.t1_notification_title_div{
    display: flex !important;
    flex-direction: column !important;
}
.notification_title_text{
    color: white !important;
}
.t1_notification_title_buttons{
    width: 50% !important;
}
.t1_notification_description_div{
    display: flex !important;
    flex-direction: row !important;
}
.notification_close{
    color: white !important;
}


.t2_notification_parent{
    background-color: @primary-color !important;
    width: 350px !important;
    top: 60px !important;
}
.t2_notification_title_div{
    display: flex !important;
    flex-direction: column !important;
}
@primary-color: #EF0855;