.expohalls_header {
  width: 100%;
  height: 80px;

  /* UI Properties */
  z-index: 2;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000000d;

  opacity: 1;
}

.expohalls_input_search {
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;

  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.expohalls_searchicon {
  position: absolute;
  top: 12px;
  left: 10px;
}

.expohalls_topblock {
  /* width: calc(100% - 622px); */
  width: 350px;
  background-color: #f1f1f1;
  height: 30px;
  position: absolute;
  top: -30px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.expohalls_bottomblock {
  /* width: calc(100% - 622px); */
  width: 350px;
  background-color: #f1f1f1;
  height: 30px;
  margin: 0px 300px;
  position: absolute;
  top: 460px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.expohalls_container {
  width: 95%;
  min-height: 460px;
  height: auto;
  margin-top: 70px;
  /* margin-bottom: 100px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  row-gap: 10px;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 10px;
  /* UI Properties */
  background: #f1f1f1 0% 0% no-repeat padding-box;
  opacity: 1;
}
.only_for_view {
  pointer-events: none;
}

.expohalls_hall {
  width: 41%;
  height: 200px;
  padding: 10px;

  position: relative;
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 40px;
}

.expohall_hallname {
  width: 100%;
  height: 30px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 75px;
}
.expohalls_hall_number {
  width: 30px;
  height: 30px;
  margin-left: 6px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal medium 14px/60px Jost;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.expohalls_booth {
  width: 17%;
  height: 55px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;

  color: #ef0855;
  opacity: 1;
}

.expohalls_booth .ant-empty-image svg {
  height: 50%;
  margin-top: 30px;
}

.expohalls_booth:hover {
  width: 17%;
  height: 55px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  color: #ef0855;
  opacity: 1;
}

.expohall_booth_logo {
  width: 100%;
  object-fit: contain;
  height: 40px;
}

.expo_brand_title_text {
  width: 10em; /* the element needs a fixed width (in px, em, %, etc) */
  overflow: hidden; /* make sure it hides the content that overflows */
  white-space: nowrap; /* don't break the line */
  text-overflow: ellipsis; /* give the beautiful '...' effect */
  text-align: center;
}

@media (max-width: 1600px) {
  .expohalls_topblock {
    width: 310px;
  }

  .expohalls_bottomblock {
    width: 310px;
  }
}

.booth_border {
  border: 2px solid #ef0855;
}

.current_booth_border {
  border: 2px solid #ef0855;
}

@media (max-width: 1000px) {
  .expohalls_hall {
    width: 100%;
  }
}

.booth_border {
  border: 2px solid #ef0855;
}

@media (max-width: 1400px) {
  .expohalls_topblock {
    width: 250px;
  }

  .expohalls_bottomblock {
    width: 250px;
  }
}

@media (max-width: 500px) {
  .expohalls_header {
    position: relative;
  }
}
