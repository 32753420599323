/* @import '~antd/dist/antd.css';*/
@import "~antd/dist/antd.less";

.trigger {
  padding: 0 15px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;

  transition: color 0.3s;
}

.logo {
  position: sticky;
  top: 0;
  display: flex;
  z-index: 99;
  height: 64px;
  background: rgba(255, 255, 255, 1);
}

.logo img {
  width: 80%;
  height: 80%;
  margin: auto;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-sider.ant-layout-sider-dark {
  // height: auto !important;
  min-height: unset;
}

@primary-color: #EF0855;