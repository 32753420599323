.modal-class > .ant-modal {
  position: relative !important;
  left: 500px !important;
  top: 400px !important;
}

.MuiPopover-root {
  /* This is done to make popup appear at back */
  z-index: 100 !important;
}

.Root-container-1 {
  height: calc(100% - 60px) !important;
}

.modal-class {
  position: relative;

  /* left : 700px !important; */
  /* border : 5px solid red; */
}

/* Admin Popups Start */

.popup_1_modal_circle {
  width: 38px;
  height: 38px;
  margin: 0;
  border-radius: 100%;
  /* margin-left: 10px !important; */

  /* UI Properties */
  background: #1428a0 0% 0% no-repeat padding-box;
  opacity: 0.7;
}
.popup_text_main {
  color: black;
}
.popup_text_main:hover {
  color: #ef0855;
}

.admin_popup_icons {
  width: 36px;
  height: 36px;
  padding: 8px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #eae4f2 !important;
  border-radius: 5px;
}

.admin_popup_icons:hover {
  width: 36px;
  height: 36px;
  padding: 8px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #eae4f2;
  /* border : 2px solid blue; */
  border-radius: 5px;
}
.my_calendar_navigations {
  color: green !important;
}
.my_calendar_navigations:hover {
  color: red !important;
}
#my_calendar_navigations {
  color: blue !important;
}
#my_calendar_navigations:hover {
  color: red !important;
}

.adminpopup2accept {
  width: 100px;
  height: 40px;
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ffffff;
  opacity: 1;
}

.adminpopup2reject {
  width: 100px;
  height: 40px;
  margin-left: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  color: #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.current_time_indicator {
  /* margin-top: 20px; */
  height: 1px;
  background: #e50e5c;
  position: absolute;
  width: 600%;
  overflow: hidden !important;
  overflow-x: hidden !important;
}

.bg_carousel_img {
  background-color: #eeeeee !important;
  /* max-height: 200px !important; */
}

/* End of Admin Popups  */

.calendar_message_modal {
  z-index: 10 !important;
  position: absolute !important;
}

.MuiPopover-paper {
  width: 550px !important;
  position: relative;
  top: 5% !important;
  left: 35% !important;
  height: auto !important;
  overflow-y: scroll !important;
  max-height: 90vh !important;
}

.MuiPopover-paper::-webkit-scrollbar {
  display: none !important;
}

/*  */

@media (max-width: 900px) {
  .Root-container-1 {
    width: 84% !important;
  }
}

@media (max-width: 500px) {
  .Root-container-1 {
    width: 80% !important;
  }

  .Root-container-221 {
    width: 80% !important;
  }
}

@media (max-width: 400px) {
  .Root-container-1 {
    width: 100% !important;
  }
}
