.btn-svg:hover svg {
  fill: #ffffff !important;
}

.background-footer-create-customer-account {
  background-color: #fbfbfb;
}
.options {
  color: grey;
}
.options:hover {
  color: rgb(238, 37, 70);
}

.filter-logo {
  height: "17.02px";
  width: "16px";
}
.filter-logo:hover {
  background-color: #ef0855;
}

.button-pink {
  border: 2px solid #ef0855;
  background-color: white;
  color: #ef0855;
}
.button-pink:hover {
  background-color: #ef0855;
  color: white;
}

.button-pink-filter {
  background-color: #ef0855;
  color: white;
  border: none;
}
.button-pink-filter:hover {
  background-color: #ef0855;
  color: white;
  border: none;
}

.bg-button-green {
  background-color: #4dcc591a;
  border: 1px solid #4dcc59;
}
.border-pink {
  border: 1px solid #ef0855;
}
.border-gray {
  border: 1px solid #e8e4ee;
}
.border-bottom-gray {
  border-bottom: 1px solid #e8e4ee;
}
.background-white-btn {
  background-color: white !important;
}
.social-media-div {
  max-height: 300px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.social-media-div::-webkit-scrollbar {
  display: none;
}
.image-upload {
}
.image-upload > input[type="file"]::-webkit-file-upload-button {
  display: none;
}
.image-upload > input {
  visibility: hidden;
}

.bg-button-green {
  background-color: #4dcc591a;
  border: 1px solid #4dcc59;
}

.bg-button-red:hover {
  background-color: rgb(255, 215, 215) !important;
  border: 1px solid red !important;
  color: black !important;
}
.agents-error-margin-top {
  margin-top: 150px;
}
.brand_input_color {
  width: 200px;
}

.spinner {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.brand_social_media_input_div {
  margin-left: -30px !important;
}

/* .table_class_test {
  height: 100% !important;
  max-height: 100% !important;
  overflow-y :auto;
} */

/* .ant-table-wrapper > .ant-table{
  height: 50% !important;
  max-height: 50% !important;

} */
/* .ant-table .ant-table-ping-right .ant-table-fixed-column .ant-table-scroll-horizontal {
  position: sticky;
  background-color: #4dcc59 !important;
} */
/* .ant-table-scroll-horizontal {
  position: sticky;
  background-color: #4dcc59 !important;
} */
/* .ant-table-wrapper {
} */

.brand_button_color {
  display: flex;
}

.brand_logo_img_preview {
  width: auto;
  height: 41px;
}

.brand_minlogo_img_preview {
  width: auto;
  height: 40px;
}

@media (max-width: 1200px) {
  .brandtheme_input_style_tablet {
    height: 36px;
  }
  .brandtheme_input_color_tablet {
    width: 150px !important;
  }
}
