/* .square_div {
    width : 30px !important;
    background-color: blue; 
} */
/* 
[data-theme='compact'] .site-navigation-steps,
.site-navigation-steps {
  margin-bottom: 60px;
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}

.customer-management-form .ant-steps.ant-steps-navigation  {
    padding-top: 0px;
    box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.10);
}

.customer-management-form .ant-steps.ant-steps-navigation .ant-steps-item{
    padding-top: 12px;
    border: 1px solid #EAE4F2;
}

.customer-management-form .ant-steps.ant-steps-navigation .ant-steps-item::after {
    display: none;
}

.customer-management-form .ant-steps.ant-steps-navigation .ant-steps-item .ant-steps-item-container {
    text-align: center;
}

.customer-management-form .ant-steps.ant-steps-navigation .ant-steps-item .ant-steps-item-container .ant-steps-item-icon {
    display: block;
    margin: auto;
}

.font-pink {
    color : rgb(238, 37, 70);
} */

.background-footer-create-customer-account {
  background-color: #fbfbfb;
}

.options {
  color: grey;
}

.options:hover {
  color: rgb(238, 37, 70);
}

.filter-logo {
  height: "17.02px";
  width: "16px";
}

.filter-logo:hover {
  background-color: #ef0855;
}

.button-pink {
  border: 2px solid #ef0855;
  background-color: white;
  color: #ef0855;
}

.button-pink:hover {
  background-color: #ef0855;
  color: white;
}

.button-pink-filter {
  background-color: #ef0855;
  color: white;
  border: none;
}

.button-pink-filter:hover {
  background-color: #ef0855;
  color: white;
  border: none;
}

.bg-button-green {
  background-color: #4dcc591a;
  border: 1px solid #4dcc59;
}

.bg-button-green:hover {
  background-color: #4dcc591a !important;
  border: 1px solid #4dcc59 !important;
  color: black !important;
}

.bg-button-red:hover {
  background-color: rgb(255, 215, 215) !important;
  border: 1px solid red !important;
  color: black !important;
}

.bg-button-green:focus {
  background-color: #4dcc591a !important;
  border: 1px solid #4dcc59 !important;
  /* color: black !important; */
}

.bg-button-red:focus {
  background-color: rgb(255, 215, 215) !important;
  border: 1px solid red !important;
  /* color: black !important; */
}

.border-pink {
  border: 1px solid #ef0855;
}

.border-gray {
  border: 1px solid #e8e4ee;
}

.border-bottom-gray {
  border-bottom: 1px solid #e8e4ee;
}

.background-white-btn {
  background-color: white !important;
}

.event-editor-increment-btn {
  background-color: #fbfbfb;
  border-color: #eae4f2;
}

.event-editor-dropdown-background-gray {
  background-color: #fbfbfb;
}

.event-editor-dropdown-div {
  /* max-height: 300px; */
  min-height: 250px;
}

.event-editor-tagged-agents-div {
  max-height: 180px;
  overflow-y: scroll;
}

.event-editor-tagged-agents-div::-webkit-scrollbar {
  display: none;
}

.ant-list-item {
  padding: 0;
  border: 0;
}

.event-editor-increment-btn:hover {
  background-color: white;
  border: 1px solid #ef0855;
}

.ant-input-group-addon:first-child {
  width: 80%;
  background-color: white;
  border: 0;
}

.minus-logo {
  /* background-color: #ef0855; */
  color: white;
  font-size: "16px";
  border: 0;
  padding: 4px;
}

.plus-logo {
  background-color: white;
  /* color: #ef0855; */
  font-size: "16px";
  border: 0;
  padding: 4px;
}

.event-date-scroll-div {
  max-height: 260px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.event-date-scroll-div::-webkit-scrollbar {
  display: none;
}

.event-setting-margin-left {
  margin-left: 60px;
}

.registration-and-communication-info-logo {
  background-color: #fbfbfb;
  border: 1px solid #dcd5e6;
  border-radius: 50%;
}

.navigation-reception-cube {
  /* color : #ef0855 !important; */
  /* background-color:  rgb(238, 37, 70); */
}

.ant-breadcrumb {
  padding-bottom: 10px;
}

/* .ant-input{
    width : 10%;
} */

.customer_regis_passInput .anticon-eye svg path {
  fill: #ef0855;
}

.listing_table .ant-table {
  border: 1px solid #e8e4ee;
  padding: 0px 0px 0 13px;
}

.listing_table .ant-table-thead > tr > th {
  color: #000;
  opacity: 0.5;
  background: #ffffff;
  border-bottom: 1px solid #e8e4ee;
}

.listing_table .ant-table-column-sorters {
  padding-right: 30px;
}

.listing_table td {
  border: none !important;
}

.listing_upper_div {
  height: 10% !important;

  /* padding : 0 50px !important; */
}
.listing_lower_div {
  height: 80% !important;
  /* padding : 0 50px !important; */
}

.cutsomer-registration-div {
  width: 40%;
}

@media (max-width: 1200px) {
  .cutsomer-registration-div {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .cutsomer-registration-div {
    width: 90%;
  }

  .cutsomer-registration-div > .ant-card-body {
    padding: 5px;
  }
}
