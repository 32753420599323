table.zone_calendar.MuiTable-root:nth-child(1) {
  display: none !important;
}

.MuiTableCell-root > div {
  text-align: center !important;
}
.MuiTableCell-root > div > div {
  color: black !important;
}
.MuiTableCell-root
  .MuiTableCell-body
  .makeStyles-cell-35
  .makeStyles-cell-47
  .makeStyles-horizontalCell-37 {
  text-align: center !important;
}

#zone_calendar_id > div > div {
  height: calc(100% - 30px) !important;
}

.MuiPaper-root {
  height: auto;
}

.MuiPopover-paper {
  outline: 0;
  position: absolute;
  min-width: 16px;
  max-height: calc(100% - 32px);
  min-height: 16px;

  overflow-x: hidden;
  overflow-y: auto;
  width: 800px !important;
  top: 5% !important;
  left: 25% !important;
}
#create-overlay > .MuiBackdrop-root {
  position: fixed !important;
}

#create-overlay > .MuiPaper-root {
  transform: translate(-50%, 0);
  left: 50% !important;
  width: 60% !important;
}

.edit_popup_header {
  /* height: 50px; */
  /* margin-bottom: 30px; */
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  background-color: #fbfbfb !important;
}

.MuiPopover-root {
  background-color: rgba(0, 0, 0, 0.45) !important;
}

.MuiPaper-rounded {
  border-radius: 0px !important;
}

.MuiPopover-paper {
  width: 950px !important;
  margin: 0 auto !important;
  position: relative !important;
  top: 5% !important;
  padding: 0 !important;
  left: 0% !important;
  padding-bottom: 0px !important;
}

.current_time_indicator {
  width: 100%;
}

.top_layout > .zone_calendar {
  height: 74vh !important;
}
