.date_time {
  height: 41px;
  /* UI Properties */
  background: #ffffff;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
}

.tagged_agents {
  height: 41px;
  /* UI Properties */
  background: #ffffff;
  border: 1px solid #e8e4ee;
  border-radius: 3px;
  opacity: 1;
}

.staffing_select_container {
  height: 180px;
  /* UI Properties */
  padding: 4px;
  background: #ffffff;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.staffing_select {
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 5px;
  opacity: 1;
}

.staffing_save_btn {
  height: 41px;
}

.staffing_inviteAgents {
  height: 44px;
}

.ant-picker-dropdown {
  z-index: 1051 !important;
}
