.entry_gate_bg {
  width: 100%;
  height: 94%;
  padding: 22px;
}

.net_lounge_box_container {
  display: flex;
  justify-content: flex-start;

  height: 100%;
  padding: 10px;
  border: 1px solid #ef0855;
  background-color: #eae4f2;
  width: auto;
  gap: 10px;

  flex-wrap: wrap;
}

.net_lounge_box {
  width: 100px;
  height: 70px;
  border: 1px solid #ef0855;
  padding: 10px;
  color: #ef0855;
  background-color: #ffffff;
  cursor: pointer;
}

.net_lounge_box:hover {
  width: 100px;
  height: 70px;
  border: 1px solid #ef0855;
  padding: 10px;
  color: #ffffff;
  background-color: #ef0855;
  cursor: pointer;
}

.net_lounge_box_text {
}
