.mess_container {
  display: flex;
  background-color: #fff;
  height: 100%;
}

.mess_list_container {
  width: 20%;
  /* width: 255px !important; */
  border: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
}

.mess_list_title_container {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
}

.mess_list_title {
  padding: 10px 10px;
}

.mess_list_user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 15px;
  padding-bottom: 0px;
}

.mess_list_user:hover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  background-color: #e6e6e6;
  cursor: pointer;
  padding: 15px;
  padding-bottom: 0px;
}

.mess_list_user:hover .mess_list_date {
  display: none;
}

.mess_list_user:hover .mess_list_bin {
  display: block;
}

.mess_user_name_container {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.mess_user_name {
  color: #000;
  font-size: 15px;
  font-weight: 600;
}

.mess_list_date {
  text-align: right;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mess_list_bin {
  display: none;
}

.mess_user_left_side {
  display: flex;
  align-items: center;
  width: 80%;
}

.mess_online_chat_dot .ant-badge-dot {
  position: absolute;
  top: 25px;
  width: 10px;
  height: 10px;
  background-color: #3bdf2f;
}

.mess_offline_chat_dot .ant-badge-dot {
  position: absolute;
  top: 25px;
  width: 10px;
  height: 10px;

  background-color: rgb(196, 196, 196);
}

.mess_unread_count {
  display: block;
  font-size: 12px;
  width: 30px;
  height: 18px;
  border-radius: 16px;
  background-color: #00bfff;
  color: #fff;
  text-align: center;
  font-weight: 700;
  line-height: 18px;

  padding: 0 9px;
  margin-right: 2px;
  opacity: 1;
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
}
/* Messages Chat */

.mess_chat_header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}

.mess_chat_header_left {
  display: flex;
  align-items: center;
}

.mess_chat_header_right {
  display: flex;
  gap: 5px;
  align-items: center;
}
.mess_area {
  overflow: auto;
  height: 80%;
  font-size: 15px;
  position: relative;
}

.mess_area_emoji_open {
  overflow: auto;
  height: 40%;
  font-size: 15px;
}

.mess_chat_container {
  width: 80%;
  border: 1px solid #eaeaea;
}

.mess_chat_user_name_container {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 100%;
}

.mess_chat_user_name {
  color: #000;
  font-size: 15px;
  font-weight: 600;
}

.mess_chat_messages {
  height: 90%;
  padding: 16px;
}

.mess_right_side_div {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  position: relative;
}

.mess_time_section {
  font-size: 12px;
  margin: 0 10px;
}

.mess_container_other {
  /* background-color: #f6f6f6; */

  padding: 10px 15px;

  border-radius: 15px;
}

.mess_other_user {
  background-color: #f6f6f6;
}

.mess_container_self {
  padding: 5px 15px;
  border-radius: 15px;
  /* background-color: rgb(239, 8, 85); */
  color: white;
}

.mess_chat_bottom_section {
  padding: 0px 11px;
  position: relative;
}

.mess_chat_bottom_attachment_container {
  background-color: rgba(20, 20, 20, 0.04);
  padding: 5px;
  height: 41px;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
}
.mess_chat_input_bottom_section {
  border-radius: 4px !important;
  height: 47px;
}

.mess_attach_inputs {
  visibility: hidden;
}

.mess_attach_video {
  margin-left: 5px;
  width: 26px;
}

.mess_chat_bottom_attachment_left_container {
  display: flex;
}

.mess_edit_bin_icons_container {
  visibility: hidden;
  position: absolute;
  background: #fff;
  top: -20px;
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.mess_right_side_div:hover .mess_edit_bin_icons_container {
  visibility: visible;
}

.mess_area::-webkit-scrollbar {
  width: 8px;
}

.mess_chat_deleted {
  background-color: #f6f6f6;
  padding: 5px;
  border-radius: 10px;
}

.mess_edit_chat_container {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(234, 234, 234);
  padding: 10px;
  position: absolute;
  top: -69px;
  width: 97%;
  background-color: #fff;
}

.crop_mess_list_user_details {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 155px;
}

/* Messages Thread */

.mess_chat_thread_container {
  width: 35%;
  border: 1px solid #eaeaea;
}

.messages_thread_replies {
  color: blue;
  margin: 0 5px;
  cursor: pointer;
}

.mess_thread_chat_header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}

@media (max-width: 1750px) {
  .mess_list_container {
    width: 22%;
  }
}

@media (max-width: 1550px) {
  .mess_list_container {
    width: 30%;
  }
  .mess_area {
    height: 72%;
  }
}

@media (max-width: 1300px) {
  .mess_list_container {
    width: 30%;
  }
  .mess_area {
    height: 66%;
  }
}
