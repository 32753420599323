.bg-pink {
  /* background-color: rgb(238, 37, 70); */
  /* min-height: 100vh; */
  background-color: #ef0855;
  /* background-image: linear-gradient(to top, rgb(238, 10, 48), rgb(204, 136, 136)); */
}
.bg-pink:hover {
  background-color: #bb0844;
}

.register_submit_btn {
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border-radius: 5px;
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ffffff;

  opacity: 1;
}

.register_submit_btn:hover {
  height: 41px;
  /* UI Properties */
  background: #bb0844 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border-radius: 5px;
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ffffff;

  opacity: 1;
}

.registration-bg-img-div {
  background-image: url("../../assets/svgs/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: auto;
  background-position: center center;
  /* min-height: 750px; */
}
.registration-div {
  /* min-width: 700px; */
  width: 90%;
}

.regis_event_logo {
  width: 200px;
  height: 54px;
}

.registartion-error-message-popup {
  width: 500px;
  height: 300px;
  background-color: white;
}

@media (max-width: 500px) {
  .registartion-error-message-popup {
    width: 300px;
    height: 300px;
  }
}
