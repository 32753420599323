.myprofile_img_container {
  min-width: 267px;
  min-height: 267px;
  /* UI Properties */
  border: 1px solid #cccccc;
  background-color: #f2f2f2;
}

.myprofilecustomer_img_container {
  min-width: 267px;
  min-height: 267px;
  /* UI Properties */
  border: 1px solid #cccccc;
  background-color: #f2f2f2;
}

.myprofile_Input {
  height: 41px;
}

.myprofile_jobInput {
  height: 41px;
}

.myprofile_textAreaInput {
  height: 184px;
}

.myprofile_select {
  height: 41px;
  /* UI Properties */
  display: flex;
  align-items: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.myprofile_passwordBtn {
  width: 259px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  letter-spacing: 0.8px;
  color: #ef0855;
  text-transform: uppercase;
}

.myprofile_delete {
  width: 232px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  letter-spacing: 0.8px;
  color: #ffffff;
  box-shadow: 0px 3px 20px #ef085533;
  letter-spacing: 0.8px;

  text-transform: uppercase;
  border-radius: 5px;
}

.myprofile_delete:hover {
  width: 232px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  letter-spacing: 0.8px;
  color: #ffffff;
  box-shadow: 0px 3px 20px #ef085533;
  letter-spacing: 0.8px;

  text-transform: uppercase;
  border-radius: 5px;
}

.myprofile_inapp_fields {
  margin-left: 21.3%;
}

.profile_modal {
  height: 200px;
}
