.profileModal_modal .ant-modal-body {
  padding: 5px;
}

.profileModal_userInfo_container {
  padding-top: 15px;
}

.profileModal_userInfo_container_people {
  height: 100px !important;
}

.profileModal_userInfoType {
  display: inline-block;
  width: 80px;
}

.profileModal_profile_header {
  height: 56px;
}
.profileModal_wrapper_modal {
  top: 70px !important;
  /* z-index: 100 !important; */
}

.profileModal_modal {
  width: 80% !important;
  z-index: 999999 !important;
}
.profileModal_profile_image {
  width: auto !important;
  height: auto;
  /* width: auto; */
  max-width: 270px;
  max-height: 250px;
}

.profileModal_user_data_bio {
  min-height: 193px;
  max-height: 193px;
  /* white-space: initial; */
  word-wrap: break-word;
  /* overflow: auto; */
}

.profileModal_border_left {
  border-left: 1px solid #eae4f2;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 900px) {
  .profileModal_profile_header {
    margin-bottom: 45px;
  }
}

@media (max-width: 700px) {
  .profileModal_modal {
    width: 100vw !important;
  }
}

@media (max-width: 500px) {
  .profileModal_wrapper_modal {
    top: 0px !important;
  }
  .profileModal_modal {
    top: 50px !important;
  }
  .profileModal_profile_header {
    height: 104px;
    column-gap: 18px;
    font-size: 12px;
    margin-bottom: 2px;
  }
  .profileModal_user_data_bio > div {
    width: 80% !important;
  }

  .profile_modal_video_call {
    margin-left: 2px !important;
  }

  .profileModal_profile_image {
    margin-left: 60px;
    width: 100px;
    height: 100px;
  }
  .user_data_card_container {
    padding: 0px !important;
  }
  .profileModal_user_data_bio > div {
    overflow-y: auto;
  }
  .mobile_view_buttons {
    font-size: 11px;
    width: auto;
  }
}
