.popup_label {
  font-weight: 500;
  font-size: 18px;
  opacity: 75;
  color: #949494;
}

.all_sposnor_images_container {
  width: 340px;
  height: 200px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  background-color: white;
  margin-bottom: 10px;
}

.all_sposnor_images {
  width: 100% !important;
  height: 100px !important;
  display: flex;
  align-items: center;
}

.all_sposnor_images {
  width: 100%;
  height: 100px;
}

.all_speaker_images_container {
  max-width: 340px;
  min-width: 340px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  background-color: white;
  margin-bottom: 10px;
}

.all_speaker_images {
  width: auto;
  height: 100%;
}

.add_to_mycal_btn {
  width: auto;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/calendarRed.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  /* letter-spacing: 0.8px; */
  color: #ef0855;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .ant-modal-body {
    padding: 14px !important;
  }
}

@media (max-width: 400px) {
  .all_speaker_images_container {
    min-width: 150px;
    height: 150px;
  }
  .add_to_mycal_btn {
    width: 170px;
  }
}
