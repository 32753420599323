.border-pink {
  border: 1px solid #ef0855;
  color: #ef0855;
}
.border-pink:hover {
  background-color: #ef0855 !important;
  color: white;
}
.assets-scroll-div {
  /* height: 250px; */
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.assets-scroll-div::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}
.agent-dropdown {
  z-index: 0 !important;
}
.agent-dropdown-styles {
  width: 300px !important;
}

.agent-dropdown-styles-tablet {
  width: 150px !important;
}

.MuiPopover-paper {
  width: 950px !important;
  height: 85vh;
  top: 10% !important;
  left: 20% !important;
}

.width-file_upload {
  width: 170px;
}

.zone-img-preview {
  max-height: 35px;
  max-width: 60px;
  margin-left: 5px;
}
