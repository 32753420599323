/* OpenMeeting.css */

/* Open Meeting */

.openmeet_table .ant-table-header {
  border-top: 1px solid #f0f0f0;
  box-shadow: none !important;
  text-align: center;
}

.openmeet_table .ant-table-thead tr th {
  text-align: center;
  height: 50px;
  font-size: 18px;
  opacity: 0.7;
}

.openmeet_table .ant-table-body tr {
  text-align: center;
}

.openmeet_table .ant-table-header {
  border-top: 1px solid #f0f0f0;
  box-shadow: none !important;
  text-align: center;
  opacity: 0.7;
}

.openmeet_table .ant-table-thead tr th {
  text-align: center;
}

.openmeet_table .ant-table-body tr td {
  height: 50px;
  font-size: 16px;
}

.openmeet_downloadBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/downloadRed.svg"); */
  /* background-position: 15px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.openmeet_downloadBtn:hover {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/download.svg"); */
  /* background-position: 15px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

.openmeet_joinBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-red.svg"); */
  color: #ef0855;
  /* background-position: 5px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.openmeet_joinBtn:hover {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-white.svg"); */
  color: #ffffff;
  /* background-position: 5px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.openmeet_deleteBtn {
  width: 140px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/bin.svg"); */
  color: #ef0855;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.openmeet_deleteBtn:hover {
  width: 140px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/binwhite.svg"); */
  color: #ffffff;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.openmeet_deleteEventBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/bin.svg"); */
  color: #ef0855;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.openmeet_deleteEventBtn:hover {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/binwhite.svg"); */
  color: #ffffff;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.openmeet_joinBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-red.svg"); */
  color: #ef0855;
  /* background-position: 5px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.openmeet_joinBtn:hover {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-white.svg"); */
  color: #ffffff;
  /* background-position: 5px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

/* End of Open Meeting */
