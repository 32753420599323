.main_chat_container {
  height: 50%;
}
.messages_area {
  overflow: auto;
  height: 80%;
  font-size: 15px;
}
.messages_area::-webkit-scrollbar {
  display: none;
}
.chat_bottom_section {
  height: 10%;
}
.send_btn {
  padding: 7px;
  margin-top: -3px;
}
.send_btn:hover {
  color: red;
}
.message_container {
  margin: 5px 0;
}
.message_header {
  display: flex;
}
.message_ {
  margin-left: 32px;
}
.message_container_other {
  padding: 10px 15px;
  background-color: rgb(234, 234, 234);
  border-radius: 15px;
}
.moderator_switch {
  margin: 0 3px;
  font-size: 28px;
}
/* .moderator_switch:hover{
    display: block;
}
.message_container_other:hover + .moderator_switch{
    display: block;
} */

.message_container_self {
  padding: 5px 15px;
  border-radius: 15px;
  background-color: rgb(239, 8, 85);
  color: white;
}
.message_container_self_trans {
  padding: 5px 15px;
  border-radius: 15px;
  background-color: rgb(239 8 85 / 41%);
  color: white;
}

.time_section {
  font-size: 12px;
  margin: 0 10px;
  margin-top: -5px;
}
.left_sided_message_div {
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

/* right sided */
.right_sided_message_div {
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.empty_chat {
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
}

.moderator_switch_svg_tick svg {
  fill: green !important;
}

.moderator_switch_svg_cancel svg {
  fill: red !important;
}
