.event_search {
  width: 290px;
  height: 35px;
  /* UI Properties */

  margin-left: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.messages_container {
  background-color: #ffffff;
  height: 80vh;
}

.messages_container_event_technical {
  background-color: #ffffff;
  height: 70vh;
}

.messages_jitsi_container {
  background-color: #ffffff;
  height: 40vh;
}

.messages_sidebar {
  /* width: 20%; */
  box-shadow: 6px 5px 10px #0000000d;
  border-radius: 5px 0px 0px 5px;
  /* height: 100%; */
  overflow: auto;
}
.messages_content {
  overflow: auto;
  max-height: 90% !important;
}

.chat-left {
  width: 305px !important;
}

.messages_search_wrapper {
  width: 90%;
  height: 41px;
  display: flex;
  align-items: center;
  background: #fbfbfb 0% 0% no-repeat padding-box;
}

.messages_subscribe {
  min-height: 50px;
  cursor: pointer;
}

.activeBtn {
  border-left: 5px solid #ef0855;
  box-shadow: 0px 0px 10px #0000001a;
}

.messages_notifications_number {
  background-color: #ef0855;
  border-radius: 100%;
  width: 1.5rem;
  height: 20px;
  display: flex;

  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
}

.messages_users {
  min-height: 70px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.messages_unsubscribeBtn {
  width: 9rem;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
  cursor: pointer;
  opacity: 1;
}

.messages_boothBtn {
  width: 9rem;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.messages_boothBtn:hover svg path {
  fill: #ffffff;
}

.messages_profileBtn {
  width: 7rem;
  height: 41px;
  cursor: pointer;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.messages_profileBtn:hover {
  width: 7rem;
  height: 41px;
  cursor: pointer;

  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
  opacity: 1;
}

.messages_profileBtn:hover svg path {
  fill: #ffffff;
}

.messages_profileBtn svg path {
  fill: #ef0855;
}

.messages_respondBtn {
  width: 8rem;
  height: 41px;
  /* UI Properties */
  cursor: pointer;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.messages_respondBtn:hover {
  width: 8rem;
  height: 41px;
  cursor: pointer;

  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
  opacity: 1;
}

.messages_respondBtn:hover svg path {
  fill: #ffffff;
}

.bg-grey {
  background-color: #fbfbfb;
}

.messages_warning_icon {
  width: 20px;
  height: 20px;
  color: #ef0855;
}

.videoBtn {
  width: 8.5rem;
  height: 41px;
  /* UI Properties */
  cursor: pointer;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.videoBtn:hover {
  width: 8.5rem;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  letter-spacing: 0.8px;
  color: #ffffff;
  cursor: pointer;

  border-radius: 5px;
  opacity: 1;
}

.videoBtn svg path {
  fill: #ef0855;
}

.videoBtn:hover svg path {
  fill: #ffffff;
}

/* Users */

.messages_users_content {
  height: 75%;
}

.sendmessage_input {
  width: 95%;
  height: 41px;

  /* UI Properties */
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
}

.message_sendBtn {
  width: 3rem;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;

  border-radius: 5px;
}

.message_sendBtn:hover {
  width: 3rem;
  height: 41px;
  background: #ef0855 0% 0% no-repeat padding-box;

  border-radius: 5px;
}

.message_sendBtn:hover svg path {
  fill: #ffffff;
}

.user_profile_modal.ant-modal.ant-modal-content {
  min-width: 80% !important;
  width: 80% !important;
}
.messageSidebar:hover {
  cursor: pointer;
}

.subscribed_channels_count {
  display: inline-block;
  font-size: 12px;
  width: 32px;
  height: 18px;
  border-radius: 16px;
  background-color: #1146ff;
  color: #fff;
  text-align: center;
  font-weight: 700;
  line-height: 18px;
  margin-left: 4px;
  padding: 0 9px;
  margin-right: 2px;
  opacity: 1;
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
}

@media (max-width: 1000px) {
  .chat__options {
    flex-direction: column !important;
    gap: 4px;
  }

  .messages_unsubscribeBtn,
  .messages_boothBtn {
    width: 140px !important;
  }

  .messages_boothBtn svg {
    margin-right: 5px;
  }

  .messages_channel_name {
    width: 30% !important;
  }
}
@media (min-width: 400px) {
  .chat-right {
    width: calc(100% - 255px) !important;
  }
}
@media (max-width: 500px) {
  .messages_channel_name {
    width: 40% !important;
  }
  .channel_buttons_mobile {
    flex-direction: column;
    row-gap: 5px;
  }
  .messages_boothBtn {
    margin-left: 0px !important;
  }
}
