.question-div {
}

.question-div::-webkit-scrollbar {
  display: none;
}

.survey_ques {
  min-height: 200px;
}

.survey_input {
  width: 90%;
  height: 41px;
}

.question_container {
  background-color: #fbfbfb;
  width: 70%;
  min-height: 300px;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  position: relative;
}
.question_container_tabview {
  background-color: #fbfbfb;
  width: 90%;
  min-height: 300px;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  position: relative;
  margin-top: 10px;
}
.question_container_modal {
  background-color: #fbfbfb;
  width: 92%;
  min-height: 300px;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  position: relative;
}

.survey_question_number {
  position: absolute;
  left: -30px;
}

.survey_delete_icon {
  position: absolute;
  right: -30px;
}

.survey_design {
  top: 200px;
  position: absolute;
}
::-webkit-input-placeholder {
  color: #e8e4ee;
  font-weight: 500;
}
