/*  Auditorium Event */
.audiEvent_downloadBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/downloadRed.svg"); */
  /* background-position: 15px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audiEvent_downloadBtn:hover {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/download.svg"); */
  /* background-position: 15px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audiEvent_brand {
  background-color: #f1f1f1;
}

.audiEvent_icons {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audiEvent_deleteBtn {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/bin.svg"); */
  color: #ef0855;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.audiEvent_deleteBtn:hover {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/binwhite.svg"); */
  color: #ffffff;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.audiEvent_joinBtn {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-red.svg"); */
  color: #ef0855;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.audiEvent_joinBtn:hover {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-white.svg"); */
  color: #ffffff;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}
