/* .column-select {
    border-left: red solid 1px;
    border-right: red solid 1px;
}

.ant-table-container table > thead > tr:nth-child(1) th:nth-child(2) {
    border-top: red solid 1px;
}

.ant-table-container table > tbody > tr:last-child td:nth-child(2) {
    border-bottom: red solid 1px;
} */

.tiers_plus_minus_btn {
    background: none;
    border: none;
}