.social_wall_event_img_container {
  width: 340px;
  height: 250px;
  display: flex !important;
  align-items: center !important;
  border: 1px solid #cccccc;
}

.social_wall_event_img {
  width: 100%;
  height: 100px;
}

.social_chat_btn {
  width: 105px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #ef0855;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_chat_btn:hover {
  width: 105px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_video_btn {
  width: 141px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #ef0855;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_video_btn:hover {
  width: 141px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_demo_btn {
  width: 225px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #ef0855;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_demo_btn:hover {
  width: 225px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_booth_btn {
  width: 154px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  color: #ef0855;
  border-radius: 5px;
}

.social_booth_btn:hover {
  width: 154px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_postBtn {
  width: 150px !important;
  height: 34px !important;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_recent_sortBtn {
  width: 129px;
  height: 41px;
  /* UI Properties */
  border: 1px solid #e8e4ee;
  color: #ef0855;
  background-repeat: no-repeat;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 5px;
}

.social_recent_sortBtn:hover {
  width: 125px;
  height: 41px;
  /* UI Properties */
  border: 1px solid #e8e4ee;
  background-color: #ef0855;
  color: #ffffff;
  background-repeat: no-repeat;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 5px;
}

.social_like_sortBtn {
  width: 111px;
  height: 41px;
  /* UI Properties */
  border: 1px solid #e8e4ee;
  color: #ef0855;
  background-repeat: no-repeat;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 5px;
}

.social_like_sortBtn:hover {
  width: 111px;
  height: 41px;
  /* UI Properties */
  border: 1px solid #e8e4ee;
  color: #ef0855;
  background-repeat: no-repeat;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 5px;
}
.social_input_post {
  width: 45%;
}

.social_postlist {
  width: 55%;
}

.social_post_container {
  max-height: 35vh;
  overflow-y: scroll !important;
  /* margin-bottom: 30px; */
}

.social_post_container::-webkit-scrollbar {
  display: none;
}

.social_post {
  width: 93%;
  height: 160px;
}

.social_heart {
  background-image: url("../../assets/svgs/heart-fill.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.social_edit {
  background-image: url("../../assets/svgs/edit-grey.svg");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
  width: 20px;
  height: 20px;
}

.social_delete {
  background-image: url("../../assets/svgs/bin-grey.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.social_removeImg {
  width: 100px;
  height: 53px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_removeImg:hover {
  width: 100px;
  height: 53px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_yesImg {
  width: 112px;
  height: 53px;
  /* UI Properties */
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #ef0855;
  border: 1px solid #ef0855;

  border-radius: 5px;
}

.social_yesImg:hover {
  width: 112px;
  height: 53px;
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #ef0855;

  border-radius: 5px;
}

.social_post_user_name {
  max-width: 50px;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* background-color: blueviolet; */
}

.vista_social_container {
  height: 600px;
  overflow: auto;
}

.vista_social_container::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 12px;
}

.social_wall_preview_img {
  width: 300px;
  height: 250px;
  object-fit: contain;
}

.social_wall_post_preview {
  width: 120px;
  height: 111px;
  object-fit: contain;
}

@media (max-width: 1000px) {
  .social_bottom {
    flex-wrap: wrap;
  }
  .social_input_post {
    width: 100%;
  }
  .social_postlist {
    width: 100%;
  }
  .responsive_vista {
    font-size: 18px;
  }
}

.social_wall_image_modal {
  padding: 10px !important;
}
.pro_pic_review .ant-modal {
  background-color: #ef0855 !important;
  width: 500px !important;
  padding: 20px;
}

.vistamap_svg {
  height: "400px";
}

.social_wall_post_content {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 53%;
  height: 53%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word; /* Important for long words! */
  white-space: pre-line;
}

.social_brand_image_mobile {
  width: auto !important;
  height: auto !important;
  max-width: 300px;
  max-height: 250px;
}

.social_brand_image_div_mobile {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 300px;
  height: 250px;
  border: 1px solid #cccccc;
  /* background-color: #cccccc; */
}

@media (max-width: 500px) {
  .social_wall_decription_mobile {
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for webkit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .social_brand_image_div_mobile {
    display: flex !important;
    justify-content: center !important;
  }

  .socialwall_header_container_mobile {
    flex-direction: column !important;
    row-gap: 10px;
  }

  .social_brand_image_mobile {
    width: 100% !important;
  }
  .social_post_user_name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
  }
  .social_wall_event_img_container {
    width: 320px;
  }
}
