/* .googleAuth_add_account_btn {

} */

.disabled2-row {
    background-color: #f5f5f5;
    /* pointer-events: none; */
  }

  .google-auth-login-btns {
    cursor: pointer;
    
  }