.net_hosting_sponsor_img_container {
  width: 340px;
  height: 250px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.hosting_sponsor_net_img_container {
  width: 520px !important;
  height: 200px !important;
  display: flex;
  align-items: center;
}

.hosting_sponsor_net_img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.networking_event_search {
  width: 290px;
  height: 35px;
  /* UI Properties */

  margin-left: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.join_table {
  width: 150px;
  height: 45px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.ant-carousel .networking_carousel {
  width: 206px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  margin-left: 260px;
  background-color: #ffffff;
  position: relative;
}

.ml-380px {
  margin-left: 380px !important;
}

.networking_carousel .slick-slide {
  min-width: 1vw;
}

.ant-carousel .networking_card_carousel {
  height: 180px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  position: relative;
}

.ant-carousel .networking_carousel .slick-dots {
  display: none !important;
  top: 0;
}

.ant-carousel .networking_card_carousel .slick-dots {
  display: none !important;
  top: 0;
}
.networking_card_carousel .slick-list {
  width: 100%;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  opacity: 0.5;
}

.networking_prev_arrow {
  position: absolute;
  top: 7px;
  z-index: 10;
  cursor: pointer;
  left: 10px;
}

.networking_next_arrow {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
}

.ant-carousel .networking_card_carousel .networking_prev_arrow {
  display: inline;
  cursor: pointer;
  position: absolute;
  top: 65px;
  z-index: 10;
}

.ant-carousel .networking_card_carousel .networking_next_arrow {
  display: inline;
  cursor: pointer;
  position: absolute;
  top: 65px;
  right: 3px;
  z-index: 10;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrowDisabled {
  border: solid gray;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  opacity: 0.5;
}

.networking_left_container {
  min-height: 60vh;
  padding: 14px 14px 5px 14px;
  min-width: 360px;
  height: auto;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 5px 0px 0px 5px;
  opacity: 1;
}

.networking_right_container {
  flex-wrap: wrap;
}

.networking_session_columns {
  margin-left: 20px;
  width: 48%;
}

.networking_col {
  width: 1000px;
}

.networking_img {
  width: 100%;
  height: 100px;
  /* UI Properties */

  border: 1px solid #e8e4ee;
}

.networking_pdfBtn {
  width: auto;
  max-width: 80%;

  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/downloadRed.svg"); */
  /* background-position: 30px; */
  color: #ef0855;

  border: 1px solid #eae4f2;
  border-radius: 5px;
}

.networking_pdfBtn:hover {
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/download.svg"); */
  /* background-position: 30px; */

  color: #ffffff;
  border: 1px solid #eae4f2;
  border-radius: 5px;
}

.networking_search {
  width: 320px;
  height: 41px;
  /* UI Properties */
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  padding-top: 3px;
  padding-left: 20px;
  color: #000000;
  opacity: 0.5;
}

.networking_date {
  max-width: 286px;
  height: 43px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #ef0855;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.networking_join_sessionBtn {
  width: 206px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-red.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.networking_join_sessionBtn:hover {
  width: 206px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-white.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

.networking_downloadBtn {
  width: 150px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/downloadRed.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.networking_downloadBtn:hover {
  width: 150px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/download.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.networking_calendarBtn {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/calendarRed.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.networking_calendarBtn:hover {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/calendar-white.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

networking_card_img {
  width: 20px;
  height: 20px;
}

.networking_session {
  width: 71%;
  height: 90px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 0.7;
}

/* .networking_sideImg {
  
} */

#net_tooltip::-webkit-scrollbar {
  width: 7px;
}

/* Track */
#net_tooltip::-webkit-scrollbar-track {
  border-radius: 5%;
  height: 100%;
  margin-bottom: 20px;
  background: #ef0855;
}

.networking_tooltipContent {
  background-color: #ef0855;
  color: #ffffff;
}

/* #net_tooltip {
  border-right: 5px solid #ef0855;
  
} */

.ant-tooltip-inner {
  background-color: #ef0855;
}

.net_search_input {
  height: 41px;
  max-width: 286px;
}

.net_options {
  width: 100%;
  min-height: 30px;
  padding: 10px;
}

.networking_asset_button_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 88%;
}

@media (max-width: 1600px) {
  .networking_session_columns {
    margin-left: 20px;
    width: 47%;
  }
}

/* 
@media (max-width: 1920px) {
  .networking_session_columns {
    margin-left: 20px;
    width: 48%;
  }
} */

@media (max-width: 1450px) {
  /* .networking_session_columns {
    margin-left: 20px;
    width: 420px;
  } */

  .networking_pdfBtn:hover {
    width: 209px;
    height: 41px;
  }
}

@media (max-width: 1290px) {
  /* .networking_session_columns {
    margin-left: 20px;
    width: 350px;
  } */
}

@media (max-width: 1200px) {
  .networking_right_container {
    flex-direction: column;
  }

  .networking_session_columns {
    margin-left: 7px;
    width: 95%;
  }
  .networking_img {
    width: 300px;
    height: 153px;

    border: 1px solid #e8e4ee;
  }
}

@media (max-width: 900px) {
  .net_hosting_sponsor_img_container {
    margin-left: auto;
    margin-right: auto;
  }
  .ant-carousel .networking_carousel {
    margin-left: 64px;
  }
  .networking_container {
    flex-wrap: wrap;
  }
  .networking_left_container {
    width: 100%;
  }
  .networking_date {
    width: 100%;
    max-width: 100%;
  }
  .net_search_input {
    width: 100%;
    max-width: 100%;
  }
  .networking_img {
    width: 100%;
    height: 190px;
  }
}
@media (max-width: 500px) {
  .join_table {
    margin-left: 88px !important;
    margin-top: 18px;
  }
  .net_hosting_sponsor_img_container {
    width: 320px;
  }
}

@media (max-width: 400px) {
  .networking_pdfBtn {
    width: 150px;
    text-align: start;
  }
}

/* @media (max-height: 700px) {
  .networking_img {
    width: 80%;
    height: 117px;
  }
  .networking_left_container > div {
    margin-bottom: 5px !important;
  }
  .networking_left_container > h6 {
    margin-bottom: 5px !important;
  }
  .networking_date {
    height: 32px;
  }
  .net_search_input {
    height: 32px;
  }
} */
