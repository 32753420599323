.logoImageModalWrapper {
  width: 100vw;
  height: 100vh;
}

.logoImageModal .ant-modal-body {
  position: relative !important;
  padding: 10px;
  width: 100%;
  height: 500px;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.logoImageModal .ant-modal-footer {
  display: flex;
  justify-content: end;
}

/* .reactEasyCrop_Contain {
  transform: translate(0px, 0px) scale(1) !important;
} */

.logoImageZoomControllerContainer {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.zoom-range {
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}
