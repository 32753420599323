.audit_hosting_sponsor_img_container {
  width: 340px;
  height: 250px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.auditorium_speaker_container {
  width: auto !important;
  height: 200px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auditorium_speaker {
  width: 200px;
  height: 200px;
  height: 100%;
  max-width: 100% !important;
  object-fit: contain;
}

.hosting_sponsor_audi_img_container {
  width: 340px !important;
  height: 200px !important;
  display: flex;
  align-items: center;
}

.hosting_sponsor_audi_img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.audi_event_search {
  width: 290px;
  height: 35px;
  /* UI Properties */

  margin-left: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.ant-carousel .audi_carousel {
  width: 206px;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  margin-left: 260px;
  background-color: #ffffff;
  position: relative;
}

/* .audi_carousel .slick-slide {
  min-width: 1vw;
} */

.ant-carousel .audi_card_carousel {
  /* height: 180px; */
  display: flex;
  align-items: center;
  padding: 0px 10px;
  position: relative;
}

.ant-carousel .audi_carousel .slick-dots {
  display: none !important;
  top: 0;
}

.ant-carousel .audi_card_carousel .slick-dots {
  display: none !important;
  top: 0;
}
.audi_card_carousel .slick-list {
  width: 100%;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  opacity: 0.5;
}

.audi_prev_arrow {
  display: inline;
  cursor: pointer;
  position: absolute;
  top: 7px;
  z-index: 10;
}

.audi_next_arrow {
  display: inline;
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.ant-carousel .audi_card_carousel .audi_prev_arrow {
  display: inline;
  cursor: pointer;
  position: absolute;
  top: 65px;
  z-index: 10;
}

.ant-carousel .audi_card_carousel .audi_next_arrow {
  display: inline;
  cursor: pointer;
  position: absolute;
  top: 65px;
  right: 3px;
  z-index: 10;
}

.audi_container {
  overflow-x: hidden;
}

.audi_left_container {
  min-height: 60vh;
  height: auto;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 5px 0px 0px 5px;
  opacity: 1;
}

.audi_col {
  width: 37%;
  margin-right: 10px;
}

.audi_img {
  width: 219px;
  height: 219px;
  /* UI Properties */
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
}

.audi_pdfBtn {
  width: auto;
  max-width: 80%;
  display: flex;
  align-items: center;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/downloadRed.svg"); */
  /* background-position: 30px; */
  color: #ef0855;

  border: 1px solid #eae4f2;
  border-radius: 5px;
}

.audi_pdfBtn:hover {
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/download.svg"); */
  /* background-position: 30px; */

  color: #ffffff;
  border: 1px solid #eae4f2;
  border-radius: 5px;
}

.audi_search_input {
  height: 41px;
}

.audi_search {
  width: 90%;
  height: 41px;
  /* UI Properties */
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding-top: 3px;
  /* padding-left: 20px; */
  color: #000000;
  opacity: 0.5;
}

.audi_date {
  width: 320px;
  height: 43px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #ef0855;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.audi_join_sessionBtn {
  width: 206px;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.audi_join_sessionBtn:hover {
  width: 206px;
  height: 41px;
  background: #ef0855 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

.audi_downloadBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/downloadRed.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audi_calendarBtn {
  width: 204px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/calendarRed.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.audi_calendarBtn:hover {
  width: 204px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/calendar-white.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

audi_card_img {
  width: 20px;
  height: 20px;
}

.audi_session {
  width: 90%;
  height: 90px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 0.7;
}

.audi_calendar_container {
  width: 100%;
  height: auto;
}

.audi_calendar_text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.audi_options {
  width: 100%;
  min-height: 30px;
  padding: 10px;
}

.audi_options:hover {
  width: 100%;
  min-height: 30px;
  padding: 10px;
}

.auditorium_img {
  width: 100%;
  height: 100px;

  /* UI Properties */

  border: 1px solid #e8e4ee;
}

.audi_session_columns {
  width: 37%;
}

.audi_right_container {
  flex-wrap: wrap;
}

.audi_net_empty_data {
  width: 100%;
  height: 100px;
  display: flex;
  margin: 20px 0;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
}

.audi_download_brands_button_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 88%;
}

@media (max-width: 1800px) {
  .audi_col {
    width: 36%;
  }
}

@media (max-width: 1600px) {
  .audi_col {
    width: 35%;
  }

  .audi_download_brands_button_text {
    font-size: 12px;
  }
}
@media (max-width: 1450px) {
  .audi_col {
    width: 50%;
  }
}

@media (max-width: 1290px) {
  .audi_col {
    width: 50%;
  }

  .audi_download_brands_button_text {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .audi_right_container {
    flex-direction: column;
  }
  .audi_col {
    width: 96%;
    margin-bottom: 10px;
  }
}

@media (max-width: 1100px) {
  .audi_col {
    width: 50%;
    margin-bottom: 10px;
  }
}
/* @media only screen and (min-width: 480px) {
  .ant-carousel .audi_carousel {
    margin-left: 30px;
  }
} */

@media (max-width: 1000px) {
  .audi_carousel .slick-slide {
    min-width: 140px;
  }
  .audi_container {
    flex-direction: column;
  }
  .audi_date {
    width: 100%;
  }
  .audi_col {
    width: 100%;
    margin-bottom: 10px;
  }

  .ant-carousel .audi_carousel {
    margin-left: 30px;
  }

  .audi_download_brands_button_text {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .audit_hosting_sponsor_img_container {
    width: 320px;
  }
}
