.side_searchbar_div {
    /* background-color: #FFF1F5; */
    border : 1px solid #FFB2CC;
    border-radius: 5px;
}
.side_searchbar_div:hover {
    width : 98% !important;
    background-color: #fffefe !important;
    border-radius: 5px !important;
}
.search_bar {
    max-height: 90vh;
    overflow-x: scroll;
}
.search_bar::-webkit-scrollbar {
    display: none;
  }