/* CreateMeeting.css */

/* Create Meeting  */
.createmeet_table .ant-table-header {
  border-top: 1px solid #f0f0f0;
  box-shadow: none !important;
  text-align: center;
}

.createmeet_table .ant-table-thead tr th {
  text-align: center;
  height: 50px;
  font-size: 18px;
  opacity: 0.7;
}

.createmeet_table .ant-table-body tr {
  text-align: center;
}

.createmeet_table .ant-table-body tr td {
  height: 50px;
  font-size: 16px;
}
.createmeet_deleteBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/bin.svg");
  color: #ef0855;
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.createmeet_deleteBtn:hover {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/binwhite.svg");
  color: #ffffff;
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.createmeet_input {
  height: 41px;
}

.createmeet_saveBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/booth-red.svg");
  color: #ef0855;
  background-position: 5px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.createmeet_saveBtn:hover {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/booth-white.svg");
  color: #ffffff;
  background-position: 5px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

/* End of Create Meeting */
