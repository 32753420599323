.brands_header {
  width: 100%;
  height: 100px;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;

  border-radius: 5px;
  opacity: 1;
}

.brands_tier_search {
  width: 40%;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  margin-right: 10px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;

  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.brands_input_search {
  width: 50%;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;

  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.brands_event_search {
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;

  letter-spacing: 0px;
  color: #000000;
}
.brands_searchicon {
  position: absolute;
  top: 12px;
  left: 10px;
}

.brands_header_btns {
  width: 100px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;

  box-shadow: 0px 3px 1px #ef085533;
  border: 1px solid #eae4f2;
  letter-spacing: 0.8px;
  color: #ef0855;
  text-transform: uppercase;
  border-radius: 5px;
}

.brands_cards_container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
  row-gap: 5px;
  column-gap: 25px;
  overflow-y: auto;
}

/* .brands_cards_container::-webkit-scrollbar {
  display: none;
} */

.brands_card::-webkit-scrollbar {
  display: none;
}

.brands_card {
  width: 23%;
  height: 194px;
  min-height: 194px;
  /* display: flex; */
  position: relative;
  overflow-x: hidden;
  word-wrap: break-word;
  padding: 15px;
  margin-bottom: 20px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  cursor: pointer;
  opacity: 1;
}

.brands_card_header {
  display: flex;
  column-gap: 10px;
  width: 100%;
}

.brands_card_img_container {
  width: 340px;

  /* UI Properties */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  opacity: 1;
}
.brands_card_header_content {
  width: 100%;
}

.brands_card_img {
  width: 100%;
  height: inherit;
}
.brands_card_header_title {
  max-width: 128px;
  margin: 0;
  overflow: hidden;
  font-size: 14px;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
}

.brands_card_header_sub_title {
  /* UI Properties */
  text-align: right;
  font-size: 11px;

  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.brands_card_header_booth {
  font-size: 0.9em;
  color: #ef0855;
  display: flex;
  column-gap: 6px;
  margin-top: 10px;
  /* position: absolute;
  top: 58px;
  left: 180px; */
}

.brands_card_header_booth svg {
  width: 16px;
}

.brands_booth_icon {
  width: 15px;
  height: 15px;
}
.brands_card_header_icon_container {
  margin-top: 10px;
  display: flex;
  column-gap: 16px;
}

.brands_card_header_icons_bg {
  width: 27px;
  height: 24px;
  display: flex;

  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  border-radius: 5px;
  opacity: 1;
}

.brands_card_header_icon {
  width: 0.9em;
  height: 0.9em;
}

.brands_card_body {
  margin-top: 5px;

  font-size: 0.8rem;
}

.brands_card_body p::-webkit-scrollbar {
  display: none;
}

.brands_card_body p {
  font-size: 11px;
  font-weight: 640;
  min-height: 35px;
  max-height: 35px;
  overflow: auto;
}

.brands_card_footer_links {
  display: flex;
  align-items: center;

  column-gap: 10px;
}

.brands_booth_btn {
  border: none;
  background-color: unset !important;
  cursor: pointer;
  margin: 0;
  padding: 0;
  height: inherit;
}

.brands_search_bar {
  height: 35px !important;
}

.brands_search_container {
  width: 50%;
}

.brands_tier_sort {
  width: 50%;
}

.brands_select_brand_container {
  width: 50%;
}

@media (max-width: 1300px) {
  .brands_card {
    width: 30%;
  }
}

@media (max-width: 1150px) {
  .brands_card {
    width: 45%;
  }
}

@media (max-width: 820px) {
  .brands_card {
    width: 80%;
  }
  .brands_header_fields_container {
    flex-direction: column;
  }

  .brands_search_container {
    width: 100%;
  }

  .brands_tier_sort {
    width: 50%;
    margin-top: 10px;
    margin-left: 49%;
  }
  .brands_cards_container {
    margin-top: 26px;
  }
  .brands_tier_search {
    width: 50%;
  }
  .brands_input_search {
    width: 100%;
  }
}
