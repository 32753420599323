/* JoinSession.css */

.joinsess_modal {
  top: 35px;
}

.join_sess_speaker_img_container {
  height: 200px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  max-width: 340px;
  min-width: 340px;
}

.join_sess_speaker_img {
  width: auto;
  height: 100%;
}

.join_sess_sponsor_img_container {
  width: 340px !important;
  height: 100px !important;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.join_sess_sponsor_img {
  width: 100%;
  height: 100px;
}

.joinsess_downloadBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/downloadRed.svg"); */
  /* background-position: 15px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.joinsess_downloadBtn:hover {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/download.svg"); */
  /* background-position: 15px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.joinsess_calendarBtn {
  width: 204px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/calendarRed.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.joinsess_calendarBtn:hover {
  width: 204px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/calendar-white.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

.joinsess_brand {
  background-color: #f1f1f1;
  border-radius: 5px;
}
.joinsess_icons {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.joinsess_audiBtn {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-red.svg"); */
  color: #ef0855;
  /* background-position: 10px; */
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.joinsess_audiBtn:hover {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-white.svg"); */
  color: #ffffff;
  /* background-position: 10px; */
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.ant-carousel .audi_card_carousel .audi_next_arrow_join_sess {
  display: inline;
  cursor: pointer;
  position: absolute;
  top: 65px;
  right: 3px;
  z-index: 10;
  right: 15px;
}

.ant-carousel .audi_card_carousel .audi_prev_arrow_joinSess {
  display: inline;
  cursor: pointer;
  position: absolute;
  top: 65px;
  z-index: 10;
  left: 15px;
}

.joinsess_brand_title {
  display: block;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 1000px) {
  .joinsess_calendarBtn {
    width: 100%;
    height: 41px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    /* background-image: url("../../assets/svgs/calendarRed.svg"); */
    /* background-position: 20px; */
    border: 1px solid #ef0855;
    letter-spacing: 0;
    color: #ef0855;
    border-radius: 5px;
  }
}
