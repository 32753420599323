/* .gravit8_header_menu_item > .ant-menu .ant-menu-item {
  padding-left: 0px;
}

.gravit8_header_menu_item_link {
  margin-left: auto;
} */

.header-expomap-title {
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 8px;
}

.header-minimize-logo {
  padding-top: 15px;
}

.gravit8_header_menu_item > .ant-menu-item span {
  margin-left: 10px;
}

.header_events_list {
  width: 281px;
}

.netUp {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.netDown {
  opacity: 1;
  /* width:100px;
     height:100px; */
  background-color: red;
  color: white;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: large;
  font-weight: 700;
  /* transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s; */
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@media (max-width: 820px) {
  .header-expomap-title {
    font-size: 12px;
  }

  .header-expomap-breadcrumbs {
    font-size: 7px;
  }
  .header_events_list {
    width: 157px;
  }
}

@media (max-width: 500px) {
  .header-expomap-title {
    font-size: 10px;
  }

  .header-expomap-sub-title {
    font-size: 10px;
  }
  .header-user-name {
    width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 9px;
  }

  .header-minimize-logo-mobile {
    width: 55px;
    height: 34px;
  }
}
