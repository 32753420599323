.survey_container {
  height: 70vh;
  margin-top: 10px;

  padding: 0% 7% 0% 7%;
  padding-top: 20px;
}

.userSurveyModal_container {
  height: 60vh;
  margin-top: 10px;

  padding: 0% 7% 0% 7%;
  padding-top: 20px;
}

.option_block {
  min-width: 15%;
  width: 20%;
}

.survey-checkbox .ant-checkbox-inner {
  height: 22px !important;
  width: 22px !important;
}

.userSurvey_buttons {
  position: "absolute";
  right: "0px";
  bottom: "10%";
}

.userSurveyModal_buttons {
  position: absolute;
  bottom: 0%;
}

@media (max-width: 500px) {
  .survey_mobile_multiple_ans_container {
    flex-direction: column;
  }
  .option_block {
    width: 100%;
  }
}
