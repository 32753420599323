.appLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 75vh;
  width: 100% !important;
  z-index: 999999;
  width: 100%;
  left: 0;
  flex-direction: column;
}

.appLoader > img {
  height: 128px;
  width: 128px;
  animation: blink 2s ease infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
