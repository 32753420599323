/* @import "antd/dist/antd.min.css"; */
/* @import "~antd/dist/antd.less"; */

.list {
  color: black !important;
  /* font-weight: 200; */
}

.list:hover > .ant-menu-title-content > .link-list {
  color: white;
}

.list:hover > svg {
  fill: #ffffff;
}

/* .ant-menu-item .ant-menu-item-selected .list > img {
  fill : #ffffff !important;
}  */

.text {
  font-size: 15px;
  text-overflow: hidden;
}

/* Bug resolved 2 */
.link-list {
  color: #ffffff;
}

.ant-menu-item a:hover {
  color: black;
}

.sidebar-div {
  max-height: 100%;
  overflow-y: auto !important;
  z-index: 5;
  scrollbar-width: none; /* Firefox */
}

.sidebar-div::-webkit-scrollbar {
  display: none;
}

.sidebar_div_mobile{
  max-width: 0 !important;
  min-width: 0 !important;
  width: 0 !important;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
  outline: none;
}

.ant-menu-item span {
  margin-left: 27px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  /* background-color: var(--primary-color); */
  color: white !important;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-item-selected svg {
  color: white;
  fill: white !important;
}

.ant-menu-item-selected svg {
  color: white;
  fill: white !important;
}

.ant-menu-item-selected > .ant-menu-title-content > .link-list {
  color: #ffffff !important;
}

/* .sidebar-div{
min-width: 210px !important;
} */

.sidebar_logo {
  width: 200px;
  height: 63px;
}
