/* NetworkingSession.css */
/* Start of Networking Session */

.networksess_card_container {
  background-color: #fbfbfb;
  border: 1px solid #e8e4ee;
}

.networkingsess_downloadBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/downloadRed.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.networkingsess_downloadBtn:hover {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/download.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

.networkingsess_audiBtn {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-red.svg"); */
  color: #ef0855;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.networkingsess_audiBtn:hover {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-white.svg"); */
  color: #ffffff;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.networkingsess_pdfBtn {
  width: 219px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/downloadRed.svg"); */
  /* background-position: 30px; */
  color: #ef0855;

  border: 1px solid #eae4f2;
  border-radius: 5px;
}

.networkingsess_pdfBtn:hover {
  width: 219px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/download.svg"); */
  /* background-position: 30px; */

  color: #ffffff;
  border: 1px solid #eae4f2;
  border-radius: 5px;
}

.networkingsess_deleteBtn {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/bin.svg"); */
  color: #ef0855;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.networkingsess_deleteBtn:hover {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/binwhite.svg"); */
  color: #ffffff;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

/* End of Networking Session */
